@import url('https://fonts.googleapis.com/css?family=Bitter:400,400i,700|Open+Sans:400,400i,600,600i,700,700i,800&display=swap');

$font-family-serif: 'Bitter';
$font-family-sans-serif: 'Open Sans';

$headings-font-family: 'Bitter';

$h2-font-size: 1.5em;
$h4-font-size: .9375em;
$font-size-p: .8125em;

