@import '../../styles/colors';

.portal {
  background-color: $lightgray;
}

.scrolling-column {
  height: 100%;
  overflow-y: scroll;
}

.fixed-height-row {
  height: 100vh;
}
